/* latin-ext */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 400 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e292f8757ca3fb64-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 400 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8de40de8211748f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Instrument Sans Fallback';src: local("Arial");ascent-override: 94.42%;descent-override: 24.33%;line-gap-override: 0.00%;size-adjust: 102.74%
}.__className_6cc2af {font-family: 'Instrument Sans', 'Instrument Sans Fallback';font-style: normal
}.__variable_6cc2af {--font-sans: 'Instrument Sans', 'Instrument Sans Fallback'
}

@font-face {
font-family: 'ballPill';
src: url(/_next/static/media/191ccfa244645d37-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: 'ballPill Fallback';src: local("Arial");ascent-override: 80.27%;descent-override: 19.03%;line-gap-override: 0.00%;size-adjust: 120.84%
}.__className_99c10e {font-family: 'ballPill', 'ballPill Fallback'
}.__variable_99c10e {--font-ball-pill: 'ballPill', 'ballPill Fallback'
}

@font-face {
font-family: 'foundryDit';
src: url(/_next/static/media/0cc144d545f05124-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: 'foundryDit Fallback';src: local("Arial");ascent-override: 86.06%;descent-override: 21.51%;line-gap-override: 3.79%;size-adjust: 116.20%
}.__className_60178b {font-family: 'foundryDit', 'foundryDit Fallback'
}.__variable_60178b {--font-foundry-dit: 'foundryDit', 'foundryDit Fallback'
}

@font-face {
font-family: 'fragmentMono';
src: url(/_next/static/media/0ecda18380a8951f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fragmentMono';
src: url(/_next/static/media/69a917b5780f8e00-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: 'fragmentMono Fallback';src: local("Arial");ascent-override: 70.14%;descent-override: 18.46%;line-gap-override: 0.00%;size-adjust: 135.44%
}.__className_d1b7f9 {font-family: 'fragmentMono', 'fragmentMono Fallback'
}.__variable_d1b7f9 {--font-fragment-mono: 'fragmentMono', 'fragmentMono Fallback'
}

